import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Star } from 'lucide-react';
import { format } from 'date-fns';
import { UsersIcon, Square3Stack3DIcon, PlusIcon, DocumentChartBarIcon, Squares2X2Icon } from '@heroicons/react/24/outline';

const NotesTab = () => {
  const [activeTab, setActiveTab] = useState('Daily Session');
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { patient } = state;

  const tabs = [
    { name: 'Daily Session', Icon: Squares2X2Icon },
    { name: 'Peer Group', Icon: UsersIcon },
    { name: 'Treatment Plan', Icon: DocumentChartBarIcon },
    { name: 'Scrap Paper', Icon: Square3Stack3DIcon },
  ];

  useEffect(() => {
    fetchNotes();
  }, [activeTab, patient.PatientID]);

  const fetchNotes = async () => {
    try {
      const response = await fetch(
        `https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_notes?patient_id=${patient.PatientID}&note_type=${activeTab}`
      );
      const data = await response.json();
      const parsedNotes = JSON.parse(data.body);
      const sortedNotes = [...parsedNotes].sort((a, b) => {
        const favoriteCompare = (b.favorited ? 1 : 0) - (a.favorited ? 1 : 0);
        if (favoriteCompare !== 0) return favoriteCompare;
        return new Date(b.date_service) - new Date(a.date_service);
      });
      setNotes(sortedNotes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const toggleFavorite = async (noteId, currentFavorited) => {
    try {
      await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/toggle_favorite', {
        method: 'POST',
        body: JSON.stringify({
          note_id: noteId,
          favorited: !currentFavorited
        })
      });
      
      setNotes(prevNotes => {
        const updatedNotes = prevNotes.map(note =>
          note.id === noteId ? { ...note, favorited: !note.favorited } : note
        );
        // Re-sort notes after updating favorite status
        return [...updatedNotes].sort((a, b) => {
          const favoriteCompare = (b.favorited ? 1 : 0) - (a.favorited ? 1 : 0);
          if (favoriteCompare !== 0) return favoriteCompare;
          return new Date(b.date_service) - new Date(a.date_service);
        });
      });
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const NoteCard = ({ note }) => {
    console.log("note: ", note);
    const [isExpanded, setIsExpanded] = useState(false);
    const noteDate = new Date(note.date_service + 'T00:00:00');
    
    const statusStyles = {
      complete: 'bg-emerald-100 text-emerald-700 border-emerald-300',
      'in-progress': 'bg-amber-100 text-amber-700 border-amber-300',
      default: 'bg-slate-100 text-slate-700 border-slate-300'
    };

    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-4 transition-all duration-200 hover:shadow-lg">
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-4">
            <div className="text-center bg-blue-50 rounded-lg p-2 w-16">
              <div className="text-2xl font-bold text-blue-700">{format(noteDate, 'dd')}</div>
              <div className="text-sm text-blue-600">{format(noteDate, 'MMM')}</div>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900">
                {note.note_type} - {note.note_subtype}
              </h3>
              <div className="mt-2 flex items-center gap-2">
                <span className={`px-3 py-1 text-sm font-medium rounded-full border ${
                  statusStyles[note.note_status.toLowerCase()] || statusStyles.default
                }`}>
                  {note.note_status}
                </span>
                {note.signed_by && (
                  <span className='px-3 py-1 text-sm font-medium rounded-full border bg-fuchsia-100 text-fuchsia-700 border-fuchsia-300'>
                    Signed
                  </span>
                )}
                {(note.charged === true) && (
                  <span className='px-3 py-1 text-sm font-medium rounded-full border bg-orange-100 text-orange-700 border-orange-300'>
                    Charged
                  </span>
                )}
                <button
                  onClick={() => navigate(`/viewnote/${note.id}`, { state: { note, patient } })}
                  className="px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-700 hover:bg-blue-200"
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => toggleFavorite(note.id, note.favorited)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <Star
              size={24}
              className={`${note.favorited ? 'text-yellow-400 fill-yellow-400' : 'text-gray-400'}`}
            />
          </button>
        </div>
        <div className="mt-4">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {isExpanded ? 'Hide Content' : 'Show Content'}
          </button>
          {isExpanded && (
            <div className="mt-2 text-sm text-gray-700 bg-gray-50 p-4 rounded-lg">
              {note.note_content}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-8">
        <div className="flex flex-wrap gap-2">
          {tabs.map((tab) => {
            const IconComponent = tab.Icon;
            return (
              <button
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
                className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
                  activeTab === tab.name
                    ? 'bg-blue-600 text-white shadow-md'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
              >
                <IconComponent className="h-5 w-5 mr-2" />
                {tab.name}
              </button>
            );
          })}
        </div>
        <button 
          onClick={() => navigate('/createnote', { state: { noteSection: activeTab, patient } })}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors shadow-md"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Create {activeTab} Note
        </button>
      </div>
      <div className="space-y-4">
        {notes.length === 0 ? (
          <div className="text-center py-8 text-gray-500">No notes available</div>
        ) : (
          notes.map(note => <NoteCard key={note.id} note={note} />)
        )}
      </div>
    </div>
  );
};

export default NotesTab;