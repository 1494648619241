import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { LockClosedIcon, UserIcon, EnvelopeIcon, PhoneIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import Header from './Header';
import EncounterHistory from './EncounterHistory';

const CaptureCharge = () => {
  const [isLocked, setIsLocked] = useState(false);
  const encounterHistoryRef = React.useRef();
  const { id } = useParams();
  const location = useLocation();
  const { patientID, note, providerName } = location.state || {};

  const [patientInfo, setPatientInfo] = useState(null);
  const [patientName, setPatientName] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientDOB, setPatientDOB] = useState('');
  const [patientInsurance, setPatientInsurance] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [patientEmail, setPatientEmail] = useState('');

  const [defaultCaseId, setDefaultCaseId] = useState(null);
  const [defaultCaseName, setDefaultCaseName] = useState(null);
  const [defaultCasePayerScenario, setDefaultCasePayerScenario] = useState(null);
  const [authNum, setAuthNum] = useState(null);
  const [tebraPatientID, setTebraPatientID] = useState(null);
  const [placeOfService, setPlaceOfService] = useState('');
  const [placeOfServiceCode, setPlaceOfServiceCode] = useState('');
  const [placeOfServiceName, setPlaceOfServiceName] = useState('');
  const [serviceLocation, setServiceLocation] = useState('');
  const practiceName = 'SENSORY STUDIO SLP OT PLLC';
  const [postDate, setPostDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });

  const [diagnosisCodes, setDiagnosisCodes] = useState([]);
  const [draggedRank, setDraggedRank] = useState(null);
  const [dropTargetRank, setDropTargetRank] = useState(null);
  const [procedureCodes, setProcedureCodes] = useState([{
    code: '', mod1: '', mod2: '', mod3: '', units: '1.00',
    charge: '', minutes: '', typeservice: '', startdate: note?.date_service, enddate: note?.date_service, diagnosis1: '', diagnosis2: '', diagnosis3: ''
  }]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeCodeIndex, setActiveCodeIndex] = useState(null);
  const modifierOptions = ['GN', 'GO', '59', '96', '97', 'KX'];
  const renderingProviders = ['Michele DeSimone', 'Jaimie Delfino', 'Diona Rosano', 'Nicole Nardone', 'Rebecca Adamo', 'Alexandra Mandara', 'Emily Rodriguez', 'Delia Pellettiere', 'Nicole Ianni', 'Stephanie Vanasco'];
  const [renderingProvider, setRenderingProvider] = useState('');
  const serviceLocationOptions = [
    '8 Johnson St- The Sensory Studio',
    '225 - The Sensory Studio',
    '445 - The Sensory Studio'
  ];
  const placeOfServiceOptions = [
    '11 - Office',
    'Other'
  ];

  const handleServiceLocationChange = (e) => {
    setServiceLocation(e.target.value);
  };

  const handleRenderingProviderChange = (e) => {
    setRenderingProvider(e.target.value);
  };

  const handlePlaceOfServiceChange = (e) => {
    const selectedPlace = e.target.value;
    setPlaceOfService(selectedPlace);
  
    if (selectedPlace === '11 - Office') {
      setPlaceOfServiceCode('11');
      setPlaceOfServiceName('Office');
    } else {
      setPlaceOfServiceCode('');
      setPlaceOfServiceName('');
    }
  };

  const procedureCodeOptions = [
    { code: '92610', description: 'Evaluation of oral and pharyngeal swallowing function', useCase: 'Intake/Re-eval', cost: '425', service: 'ST' },
    { code: '92523', description: 'Evaluation of speech sound production, language comprehension, language expression', useCase: 'Intake/Re-eval', cost: '550', service: 'ST' },
    { code: '92524', description: 'Evaluation of behavioral and qualitative analysis of voice and resonance', useCase: 'Intake/Re-eval', cost: '260', service: 'ST' },
    { code: '92521', description: 'Evaluation of speech fluency (ONLY BILL 1 TIME AT INTAKE)', useCase: 'Intake', cost: '200', service: 'ST' },
    { code: '99366', description: 'Medical team conference (30mins)', useCase: 'Intake/Re-eval', cost: '295', mins: '30', service: 'ST' },
    { code: '99203', description: 'Evaluation of new patient (30mins)', useCase: 'Intake', cost: '250', mins: '30', service: 'ST' },
    { code: '99213', description: 'Evaluation of established patient (15mins)', useCase: 'Re-eval', cost: '125', mins: '15', service: 'ST' },
    { code: '92526', description: 'Treatment of swallowing dysfunction and/or oral function for feeding', useCase: 'Session', cost: '350', service: 'ST' },
    { code: '92507', description: 'Treatment of speech, language, voice, communication, and/or auditory processing disorder', useCase: 'Session', cost: '260', service: 'ST' },
    { code: '97535', description: 'Self-Care/Home Management Training (15mins)- (min. 8mins)', useCase: 'Session', cost: '235', mins: '15', service: 'ST' },
    { code: '97165', description: 'Low complexity (30mins)', useCase: 'Intake', cost: '305', mins: '30', service: 'OT' },
    { code: '97166', description: 'Moderate complexity (45mins)', useCase: 'Intake', cost: '455', mins: '45', service: 'OT' },
    { code: '97167', description: 'High complexity (60mins)', useCase: 'Intake', cost: '610', mins: '60', service: 'OT' },
    { code: '97168', description: 'Reevaluation (30mins)', useCase: 'Re-eval', cost: '255', mins: '30', service: 'OT' },
    { code: '97110', description: 'Therapeutic exercise (15 mins)', useCase: 'Session', cost: '210', mins: '15', service: 'OT' },
    { code: '97530', description: 'Therapeutic Activities (15mins)', useCase: 'Session', cost: '225', mins: '15', service: 'OT' },
    { code: '97550', description: 'Caregiver Training (1st 30mins)', useCase: '', cost: '150' },
    { code: '97551', description: 'Caregiver Training (Each addl 15mins)', useCase: '', cost: '100' }
  ];
  
  const diagnosisOptions = [
    { code: 'R13.11', description: 'Dysphagia, oral phase' },
    { code: 'R13.12', description: 'Dysphagia, oropharyngeal phase' },
    { code: 'R48.2', description: 'Apraxia' },
    { code: 'F80.2', description: 'Mix receptive/expressive language disorder' },
    { code: 'F80.81', description: 'Childhood onset fluency disorder' },
    { code: 'F98.8', description: 'Other behavior/emotion disorder' },
    { code: 'F84.0', description: 'Autistic disorder' },
    { code: 'M62.81', description: 'Muscle weakness' },
    { code: 'R62.50', description: 'Unspecified lack of expected development' },
    { code: 'R62.0', description: 'Delayed milestone in childhood' },
    { code: 'R27.9', description: 'Unspecified lack of coordination' },
    { code: 'R27', description: 'Other lack of coordination' },
    { code: 'R48.8', description: 'Other symbolic dysfunctions' },
    { code: 'F82', description: 'Specific developmental disorder of motor function' }
  ];

  const getLockedFieldClass = (isLocked) => {
    return `text-black w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 ${
      isLocked ? 'border-blue-500 border-2 bg-red-100' : ''
    }`;
  };

  // Create a reusable className for locked selects
  const getLockedSelectClass = (isLocked) => {
    return `flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 ${
      isLocked ? 'border-blue-500 border-2 bg-red-100' : ''
    }`;
  };

  const handleReloadEncounter = (encounter) => {
    // Set isLocked based on whether the encounter is being auto-loaded
    const isAutoLoad = encounter.isLocked || false;
    setIsLocked(isAutoLoad);

    // Update all form fields with encounter data
    if (encounter.patient_name) {
      setPatientName(encounter.patient_name);
    }
    if (encounter.patient_gender) {
      setPatientGender(encounter.patient_gender);
    }
    if (encounter.patient_dob) {
      setPatientDOB(encounter.patient_dob);
    }
    if (encounter.patient_insurance) {
      setPatientInsurance(encounter.patient_insurance);
    }
    if (encounter.patient_phone) {
      setPatientPhone(encounter.patient_phone);
    }
    if (encounter.patient_email) {
      setPatientEmail(encounter.patient_email);
    }

    // Update case information
    setDefaultCaseId(encounter.case_id || '');
    setDefaultCaseName(encounter.case_name || '');
    setDefaultCasePayerScenario(encounter.case_payer_scenario || '');
    setTebraPatientID(encounter.tebra_patient_id || '');
    setAuthNum(encounter.auth_number || '');

    // Update service information
    setPostDate(encounter.post_date || new Date().toISOString().split('T')[0]);
    setServiceLocation(encounter.service_location || '');
    setPlaceOfService(encounter.place_of_service || '');
    setPlaceOfServiceCode(encounter.place_of_service_code || '');
    setRenderingProvider(encounter.rendering_provider || '');

    // Update diagnosis codes from the first procedure code line
    if (encounter.procedure_codes && Array.isArray(encounter.procedure_codes) && encounter.procedure_codes.length > 0) {
      const firstProcedure = encounter.procedure_codes[0];
      const diagnosisCodes = [];
      
      // Only add diagnosis codes that exist
      if (firstProcedure.diagnosis1) {
        diagnosisCodes.push({
          rank: 1,
          code: firstProcedure.diagnosis1,
          id: 'diagnosis-1'
        });
      }
      
      if (firstProcedure.diagnosis2) {
        diagnosisCodes.push({
          rank: 2,
          code: firstProcedure.diagnosis2,
          id: 'diagnosis-2'
        });
      }
      
      if (firstProcedure.diagnosis3) {
        diagnosisCodes.push({
          rank: 3,
          code: firstProcedure.diagnosis3,
          id: 'diagnosis-3'
        });
      }
      
      setDiagnosisCodes(diagnosisCodes);
    }

    // Update procedure codes
    if (encounter.procedure_codes && Array.isArray(encounter.procedure_codes)) {
      const formattedProcedureCodes = encounter.procedure_codes.map(proc => ({
        code: proc.code || '',
        mod1: proc.mod1 || '',
        mod2: proc.mod2 || '',
        mod3: proc.mod3 || '',
        units: proc.units || '1.00',
        charge: proc.charge || '',
        minutes: proc.minutes || '',
        typeservice: proc.typeservice || '',
        startdate: proc.startdate || encounter.post_date || '',
        enddate: proc.enddate || encounter.post_date || '',
        diagnosis1: proc.diagnosis1 || '',
        diagnosis2: proc.diagnosis2 || '',
        diagnosis3: proc.diagnosis3 || ''
      }));
      setProcedureCodes(formattedProcedureCodes);
    }
  };

  useEffect(() => {
    console.log("Procedure Line Request", procedureCodes);
  })

  useEffect(() => {
    const updatedProcedureCodes = procedureCodes.map(procedure => {
      // Extract only the diagnosis codes without descriptions
      const diagnosisList = diagnosisCodes
        .filter(dx => dx.code) // Filter out empty diagnosis codes
        .map(dx => dx.code.split(' - ')[0]); // Extract just the code part

      return {
        ...procedure,
        diagnosis1: diagnosisList[0] || '',
        diagnosis2: diagnosisList[1] || '',
        diagnosis3: diagnosisList[2] || ''
      };
    });

    setProcedureCodes(updatedProcedureCodes);
  }, [diagnosisCodes]);

  useEffect(() => {
    if (patientID) {
      fetchPatientInfo();
    }
  }, [patientID]);

  useEffect(() => {
    if (patientInfo) {
      setPatientName(`${patientInfo.FirstName} ${patientInfo.LastName}`);
      setPatientGender(patientInfo.Gender);
      setPatientDOB(patientInfo.DOB);
      setPatientInsurance(patientInfo.InsuranceCompany);
      setPatientPhone(patientInfo.PhoneNumber);
      setPatientEmail(patientInfo.Email);
    }
  }, [patientInfo]);

  const fetchPatientInfo = async () => {
    try {
      // First, fetch patient info from the existing endpoint
      const patientResponse = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
        headers: {
          PatientID: patientID.toString()
        }
      });
      
      if (patientResponse.data?.body) {
        let patientData = typeof patientResponse.data.body === 'string' 
          ? JSON.parse(patientResponse.data.body) 
          : patientResponse.data.body;
        
        if (Array.isArray(patientData) && patientData.length > 0) {
          setPatientInfo(patientData[0]);
          
          // Now fetch case ID using patient's first and last name
          try {
            const caseResponse = await axios.post(
              'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/tebra_get_patient', 
              {
                FirstName: patientData[0].FirstName,
                LastName: patientData[0].LastName
              }
            );

            console.log("Case Response:", caseResponse);
            
            if (caseResponse.data?.body) {
              let caseData = typeof caseResponse.data.body === 'string'
                ? JSON.parse(caseResponse.data.body)
                : caseResponse.data.body;

              const defaultCaseId = caseData?.["s:Envelope"]?.["s:Body"]?.GetPatientsResponse?.GetPatientsResult?.Patients?.PatientData?.DefaultCaseID;
              const defaultCaseName = caseData?.["s:Envelope"]?.["s:Body"]?.GetPatientsResponse?.GetPatientsResult?.Patients?.PatientData?.DefaultCaseName;
              const defaultCasePayerScenario = caseData?.["s:Envelope"]?.["s:Body"]?.GetPatientsResponse?.GetPatientsResult?.Patients?.PatientData?.DefaultCasePayerScenario;
              const tebraPatientId = caseData?.["s:Envelope"]?.["s:Body"]?.GetPatientsResponse?.GetPatientsResult?.Patients?.PatientData?.ID;
              
              if (defaultCaseId) {
                console.log("Case ID:", defaultCaseId);
                setDefaultCaseId(defaultCaseId);
              };
              if (defaultCaseName) {
                setDefaultCaseName(defaultCaseName);
              };
              if (defaultCasePayerScenario) {
                setDefaultCasePayerScenario(defaultCasePayerScenario);
              };
              if (tebraPatientId) {
                setTebraPatientID(tebraPatientId);
              };
            }
          } catch (caseError) {
            console.error('Error fetching case ID:', caseError);
          }
        } else {
          setError("No patient data found");
        }
      }
    } catch (error) {
      console.error('Error fetching patient info:', error);
      setError("Error fetching patient data");
    }
  };

  const addDiagnosisCode = () => {
    if (diagnosisCodes.length < 12) {
      const newRank = diagnosisCodes.length + 1;
      setDiagnosisCodes([...diagnosisCodes, { 
        rank: newRank, 
        code: '',
        id: `diagnosis-${newRank}` // Add unique id for drag-drop
      }]);
    }
  };

  const updateDiagnosisCode = (index, value) => {
    const updatedCodes = [...diagnosisCodes];
    updatedCodes[index].code = value;
    setDiagnosisCodes(updatedCodes);
    setSearchQuery(value);
    setShowDropdown(true);
    setActiveCodeIndex(index);
  };

  const selectDiagnosisCode = (code, description) => {
    const updatedCodes = [...diagnosisCodes];
    updatedCodes[activeCodeIndex].code = `${code} - ${description}`;
    setDiagnosisCodes(updatedCodes);
    setShowDropdown(false);
    setSearchQuery('');
  };

  const handleDragStart = (e, rank) => {
    setDraggedRank(rank);
    e.currentTarget.classList.add('opacity-50');
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove('opacity-50');
    setDraggedRank(null);
    setDropTargetRank(null);
  };

  const handleDragOver = (e, rank) => {
    e.preventDefault();
    setDropTargetRank(rank);
  };

  const handleDrop = (e, targetRank) => {
    e.preventDefault();
    if (draggedRank === targetRank) return;

    const updatedCodes = [...diagnosisCodes];
    const draggedCode = updatedCodes.find(code => code.rank === draggedRank);
    const targetCode = updatedCodes.find(code => code.rank === targetRank);

    if (draggedCode && targetCode) {
      // Store the codes temporarily
      const draggedCodeContent = draggedCode.code;
      const targetCodeContent = targetCode.code;

      // Swap the codes while keeping the ranks
      draggedCode.code = targetCodeContent;
      targetCode.code = draggedCodeContent;
    }
    
    setDiagnosisCodes(updatedCodes);
    setDraggedRank(null);
    setDropTargetRank(null);
  };

  const addProcedureCode = () => {
    const existingDiagnoses = procedureCodes[0] ? {
      diagnosis1: procedureCodes[0].diagnosis1,
      diagnosis2: procedureCodes[0].diagnosis2,
      diagnosis3: procedureCodes[0].diagnosis3
    } : {
      diagnosis1: '',
      diagnosis2: '',
      diagnosis3: ''
    };
    
    setProcedureCodes([...procedureCodes, {
      code: '', mod1: '', mod2: '', mod3: '', units: '1.00',
      charge: '', minutes: '', typeservice: '', startdate: note?.date_service, enddate: note?.date_service, ...existingDiagnoses
    }]);
  };

  const updateProcedureCode = (index, field, value) => {
    const updatedCodes = [...procedureCodes];
    
    // If the field is 'code', find the corresponding cost
    if (field === 'code') {
      const selectedProcedure = procedureCodeOptions.find(option => option.code === value);
      
      updatedCodes[index] = {
        ...updatedCodes[index],
        [field]: value,
        // Automatically set the cost if a matching procedure is found
        charge: selectedProcedure ? selectedProcedure.cost : '',
        // Automatically set the type of service if available
        typeservice: selectedProcedure && selectedProcedure.service ? selectedProcedure.service : '',
        // Automatically set the minutes if available
        minutes: selectedProcedure && selectedProcedure.mins ? selectedProcedure.mins : ''
      };
    } else {
      // For other fields, update as before
      updatedCodes[index][field] = value;
    }
    
    setProcedureCodes(updatedCodes);
  };

  const filteredDiagnosisCodes = diagnosisOptions.filter(option => {
    const searchTerm = searchQuery.toLowerCase();
    return option.code.toLowerCase().includes(searchTerm) || 
           option.description.toLowerCase().includes(searchTerm);
  });

  const submitCharge = async () => {
    // Validate required fields
    if (!patientInfo || !renderingProvider) {
      alert('Please ensure Patient Information and Rendering Provider are complete');
      return;
    }
  
    // Split rendering provider name
    const [renderingProviderFirstName, renderingProviderLastName] = renderingProvider.split(' ');
  
    // Prepare procedure code line requests
    const procedureLineRequests = procedureCodes.map(procedure => {
      // Dynamically create modifiers array, excluding empty values
      const modifiers = ['mod1', 'mod2', 'mod3']
        .map(mod => procedure[mod])
        .filter(modifier => modifier && modifier.trim() !== '');
  
      // Dynamically create diagnosis codes, excluding empty values
      const diagnosisCodes = ['diagnosis1', 'diagnosis2', 'diagnosis3']
        .map(dx => procedure[dx])
        .filter(diagnosis => diagnosis && diagnosis.trim() !== '');
  
      return {
        procedure_code: procedure.code,
        service_start_date: procedure.startdate || note?.date_service,
        service_end_date: procedure.enddate || note?.date_service,
        type_of_service: procedure.typeservice,
        unit_charge: procedure.charge,
        units: procedure.units,
        diagnosis_codes: diagnosisCodes,
        procedure_modifiers: modifiers
      };
    });
  
    // Prepare request body
    const requestBody = {
      authorization_number: authNum,
      case_id: defaultCaseId,
      case_name: defaultCaseName,
      case_payer_scenario: defaultCasePayerScenario,
      patient_first_name: patientInfo.FirstName,
      patient_last_name: patientInfo.LastName,
      patient_tebra_id: tebraPatientID,
      place_of_service_code: placeOfServiceCode,
      place_of_service_name: placeOfServiceName,
      post_date: postDate,
      practice_name: practiceName,
      rendering_provider_first_name: renderingProviderFirstName,
      rendering_provider_last_name: renderingProviderLastName,
      service_location_name: serviceLocation,
      service_start_date: note?.date_service,
      service_end_date: note?.date_service,
      procedure_line_requests: procedureCodes.map(procedure => ({
        procedure_code: procedure.code,
        service_start_date: procedure.startdate || note?.date_service,
        service_end_date: procedure.enddate || note?.date_service,
        type_of_service: procedure.typeservice,
        unit_charge: procedure.charge,
        units: procedure.units,
        diagnosis_codes: [
          procedure.diagnosis1, 
          procedure.diagnosis2, 
          procedure.diagnosis3
        ].filter(dx => dx && dx.trim() !== ''),
        procedure_modifiers: [
          procedure.mod1, 
          procedure.mod2, 
          procedure.mod3
        ].filter(mod => mod && mod.trim() !== ''),
        ...(procedure.minutes ? { minutes: procedure.minutes } : {})
      }))
    };

    console.log("Request Body: ", requestBody);
  
    try {
      const tebraResponse = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/tebra_create_encounter', 
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      // Parse the stringified response body
      let responseData = typeof tebraResponse.data.body === 'string' 
        ? JSON.parse(tebraResponse.data.body) 
        : tebraResponse.data.body;

      const soapBody = responseData["s:Envelope"]["s:Body"];
      const encounterResponse = soapBody.CreateEncounterResponse?.CreateEncounterResult;

      if (encounterResponse?.ErrorResponse?.IsError === "false") {
        const encounterId = encounterResponse.EncounterID;
        
        // Prepare the encounter data for our database
        const encounterData = {
          encounter_id: encounterId,
          patient_name: `${patientInfo.FirstName} ${patientInfo.LastName}`,
          patient_phone: patientPhone,
          patient_email: patientEmail,
          patient_gender: patientGender,
          patient_dob: patientDOB,
          patient_insurance: patientInsurance,
          case_id: defaultCaseId,
          case_name: defaultCaseName,
          case_payer_scenario: defaultCasePayerScenario,
          auth_number: authNum,
          tebra_patient_id: tebraPatientID,
          post_date: postDate,
          service_location: serviceLocation,
          place_of_service: placeOfService,
          place_of_service_code: placeOfServiceCode,
          rendering_provider: renderingProvider,
          practice_name: practiceName,
          diagnosis_codes: diagnosisCodes,
          procedure_codes: procedureCodes,
          timestamp: new Date().toISOString()
        };

        // Save to our database
        await axios.post(
          'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_encounter_rds',
          {
            encounter_data: encounterData,
            note_id: id  // from the component's state
          }
        );

        // Update the encounter history
        if (encounterHistoryRef.current) {
          encounterHistoryRef.current.addEncounter(encounterData);
        }

        alert(`Charge submitted successfully! Encounter ID: ${encounterId}`);
      } else {
        throw new Error(encounterResponse.ErrorResponse?.ErrorMessage || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error submitting charge:', error);
      alert(`Failed to submit charge: ${error.message}`);
    }
  };

  // Add this useEffect at the component level
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('input') && !event.target.closest('.absolute')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto pt-24">
        <h1 className="text-3xl font-bold mb-8">Capture Charge</h1>
        {/* Patient Information Section */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className='flex gap-2'>
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Patient Information</h2>
            {isLocked ? (
              <LockClosedIcon className='h-7 w-7 text-black' />
            ) : (
              <LockOpenIcon className='h-7 w-7 text-black' />
            )}
          </div>
          {patientInfo ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Full Name:</label>
                  <div className="flex items-center">
                    <UserIcon className="h-5 w-5 text-gray-500 mr-2" />
                    <input 
                      type="text" 
                      value={patientName}
                      disabled={isLocked}
                      onChange={(e) => setPatientName(e.target.value)}
                      className={getLockedFieldClass(isLocked)}
                    />
                  </div>
                </div>
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Gender:</label>
                  <input 
                    type="text" 
                    value={patientGender}
                    disabled={isLocked}
                    onChange={(e) => setPatientGender(e.target.value)}
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Case ID:</label>
                  <input 
                    type="text" 
                    value={defaultCaseId || 'Not Available'}
                    disabled={isLocked}
                    readOnly
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Number:</label>
                  <input 
                    type="text" 
                    value={authNum}
                    disabled={isLocked}
                    onChange={(e) => setAuthNum(e.target.value)}
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
              </div>
              <div className="space-y-2">
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Phone Number:</label>
                  <div className="flex items-center">
                    <PhoneIcon className="h-5 w-5 text-gray-500 mr-2" />
                    <input 
                      id="phonenumba"
                      type="tel" 
                      value={patientPhone}
                      disabled={isLocked}
                      onChange={(e) => setPatientPhone(e.target.value)}
                      className={getLockedFieldClass(isLocked)}
                    />
                  </div>
                </div>
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance Company:</label>
                  <input 
                    type="text" 
                    value={patientInsurance}
                    disabled={isLocked}
                    onChange={(e) => setPatientInsurance(e.target.value)}
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Case Name:</label>
                  <input 
                    type="text" 
                    value={defaultCaseName || 'Not Available'}
                    disabled={isLocked}
                    readOnly
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Tebra Patient ID:</label>
                  <input 
                    type="text" 
                    value={tebraPatientID}
                    disabled={isLocked}
                    readOnly
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Email Address:</label>
                  <div className="flex items-center">
                    <EnvelopeIcon className="h-5 w-5 text-gray-500 mr-2" />
                    <input 
                      type="email" 
                      value={patientEmail}
                      disabled={isLocked}
                      onChange={(e) => setPatientEmail(e.target.value)}
                      className={getLockedFieldClass(isLocked)}
                    />
                  </div>
                </div>
                <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Date of Birth:</label>
                  <input 
                    type="text" 
                    value={patientDOB}
                    disabled={isLocked}
                    onChange={(e) => setPatientDOB(e.target.value)}
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Case Payer Scenario:</label>
                  <input 
                    type="text" 
                    disabled={isLocked}
                    value={defaultCasePayerScenario || 'Not Available'}
                    readOnly
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Post Date:</label>
                  <input 
                    type="date" 
                    disabled={isLocked}
                    value={postDate}
                    onChange={(e) => setPostDate(e.target.value)}
                    className={getLockedFieldClass(isLocked)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">Loading patient information...</p>
          )}
        </div>

        {/* Event Information Section */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className='flex gap-2'>
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Patient Information</h2>
            {isLocked ? (
              <LockClosedIcon className='h-7 w-7 text-black' />
            ) : (
              <LockOpenIcon className='h-7 w-7 text-black' />
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Provider:</label>
              <div className="flex items-center flex-1">
                <input 
                  type="text" 
                  value={providerName || ''}
                  disabled={isLocked}
                  className={getLockedFieldClass(isLocked)}
                />
              </div>
            </div>
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Service Date:</label>
              <input 
                type="date" 
                value={note?.date_service || ''}
                disabled={isLocked}
                className={getLockedFieldClass(isLocked)}
              />
            </div>
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Service Location:</label>
              <select
                value={serviceLocation || ''}
                disabled={isLocked}
                onChange={handleServiceLocationChange}
                className={getLockedSelectClass(isLocked)}
              >
                <option value="">Select Location</option>
                {serviceLocationOptions.map((location) => (
                  <option key={location} value={location}>{location}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Place of Service:</label>
              <select
                value={placeOfService || ''}
                disabled={isLocked}
                onChange={handlePlaceOfServiceChange}
                className={getLockedSelectClass(isLocked)}
              >
                <option value="">Select Place</option>
                {placeOfServiceOptions.map((place) => (
                  <option key={place} value={place}>{place}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Rendering Provider:</label>
              <select
                value={renderingProvider || ''}
                onChange={handleRenderingProviderChange}
                disabled={isLocked}
                className={getLockedSelectClass(isLocked)}
              >
                <option value="">Select Rendering Provider</option>
                {renderingProviders.map((provider) => (
                  <option key={provider} value={provider}>{provider}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label className="w-32 font-medium text-gray-700">Practice:</label>
              <div className="flex items-center flex-1">
                <input 
                  type="text" 
                  disabled={isLocked}
                  value={practiceName || ''}
                  className={getLockedFieldClass(isLocked)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Diagnosis Codes Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className='flex gap-2'>
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Diagnosis Codes (Max 12)</h2>
            {isLocked ? (
              <LockClosedIcon className='h-7 w-7 text-black' />
            ) : (
              <LockOpenIcon className='h-7 w-7 text-black' />
            )}
          </div>
      <div className="mb-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-1/6 text-left pb-2 text-blue-600">Rank</th>
              <th className="w-5/6 text-left pb-2 text-blue-600">Diagnosis Code</th>
            </tr>
          </thead>
          <tbody>
            {diagnosisCodes.map((code) => (
              <tr key={code.id}>
                <td className="pr-4 py-2">
                  <div
                    draggable
                    onDragStart={(e) => handleDragStart(e, code.rank)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => handleDragOver(e, code.rank)}
                    onDrop={(e) => handleDrop(e, code.rank)}
                    className={`
                      w-8 h-8 flex items-center justify-center 
                      bg-blue-100 border-2 border-blue-500 rounded 
                      cursor-move font-semibold text-blue-700
                      ${dropTargetRank === code.rank ? 'border-dashed' : ''}
                      transition-all duration-200
                    `}
                  >
                    {code.rank}
                  </div>
                </td>
                <td className="py-2">
                  <div className="relative">
                    <input
                      type="text"
                      disabled={isLocked}
                      value={code.code}
                      onChange={(e) => updateDiagnosisCode(
                        diagnosisCodes.findIndex(c => c.rank === code.rank),
                        e.target.value
                      )}
                      onFocus={() => {
                        setShowDropdown(true);
                        setActiveCodeIndex(diagnosisCodes.findIndex(c => c.rank === code.rank));
                      }}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                      placeholder="Type to search diagnosis codes..."
                    />
                    {showDropdown && activeCodeIndex === diagnosisCodes.findIndex(c => c.rank === code.rank) && (
                      <div 
                        className="absolute z-50 left-0 right-0 mt-1 bg-white border rounded-md shadow-lg"
                        style={{ maxHeight: '200px', overflowY: 'auto' }}
                      >
                        {filteredDiagnosisCodes.map((option, i) => (
                          <div
                            key={i}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectDiagnosisCode(option.code, option.description)}
                          >
                            {option.code} - {option.description}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button 
        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors"
        onClick={addDiagnosisCode}
        disabled={diagnosisCodes.length >= 12}
      >
        Add Diagnosis Code
      </button>
    </div>

        {/* Procedure Codes Section - updated with better horizontal scrolling */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className='flex gap-2'>
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Procedure Codes</h2>
            {isLocked ? (
              <LockClosedIcon className='h-7 w-7 text-black' />
            ) : (
              <LockOpenIcon className='h-7 w-7 text-black' />
            )}
          </div>
          <div className="overflow-x-auto">
            <div className="min-w-max"> {/* Added to ensure minimum width */}
              <table className="w-full mb-4">
                <thead>
                  <tr>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '300px' }}>Code</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '100px' }}>Mod 1</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '100px' }}>Mod 2</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '100px' }}>Mod 3</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '100px' }}>Units</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Charge</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Minutes</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Dx 1</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Dx 2</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Dx 3</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>Start Date</th>
                  <th className="text-left pb-2 px-2 text-blue-600" style={{ minWidth: '120px' }}>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {procedureCodes.map((procedure, index) => (
                    <tr key={index}>
                      <td className="px-2 py-2">
                      <select
                        value={procedure.code}
                        disabled={isLocked}
                        onChange={(e) => updateProcedureCode(index, 'code', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Select Procedure Code</option>
                        {procedureCodeOptions.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.code && (
                              <>
                                <strong>{option.code}</strong>
                                {option.description && ` - ${option.description}`}
                                {option.useCase && ` - ${option.useCase}`}
                              </>
                            )}
                          </option>
                        ))}
                      </select>
                    </td>
                      {['mod1', 'mod2', 'mod3'].map((mod) => (
                        <td key={mod} className="px-2 py-2">
                          <select
                            value={procedure[mod]}
                            disabled={isLocked}
                            onChange={(e) => updateProcedureCode(index, mod, e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="">Select</option>
                            {modifierOptions.map((option) => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </td>
                      ))}
                      {['units', 'charge', 'minutes', 'diagnosis1', 'diagnosis2', 'diagnosis3'].map((field) => (
                        <td key={field} className="px-2 py-2">
                          <input
                            type="text"
                            disabled={isLocked}
                            value={procedure[field]}
                            onChange={(e) => updateProcedureCode(index, field, e.target.value)}
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                          />
                        </td>
                      ))}
                      <td className="px-2 py-2">
                        <input
                          type="date"
                          value={procedure.startdate}
                          disabled={isLocked}
                          onChange={(e) => updateProcedureCode(index, 'startdate', e.target.value)}
                          className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        />
                      </td>
                      <td className="px-2 py-2">
                        <input
                          type="date"
                          value={procedure.enddate}
                          disabled={isLocked}
                          onChange={(e) => updateProcedureCode(index, 'enddate', e.target.value)}
                          className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors"
            onClick={addProcedureCode}
          >
            Add Procedure Code
          </button>
        </div>

        <EncounterHistory 
          ref={encounterHistoryRef}
          onReloadEncounter={handleReloadEncounter}
          patientName={patientName}
          currentNoteId={id}
        />

        {/* Submit Button */}
        <div className="flex justify-end mb-8">
        <button
          className={`${
            isLocked 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-green-500 hover:bg-green-600'
          } text-white px-6 py-3 rounded-lg text-lg font-semibold transition-colors`}
          onClick={submitCharge}
          disabled={isLocked}
        >
          {isLocked ? 'Encounter Locked' : 'Submit Charge'}
        </button>
        </div>
      </div>
    </div>
  );
};

export default CaptureCharge;
