import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';

const EncounterHistory = forwardRef(({ onReloadEncounter, patientName, currentNoteId }, ref) => {
  const [encounters, setEncounters] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEncounters = async () => {
    if (!patientName?.trim()) {
      console.log('Skipping fetch - no patient name provided');
      return;
    }
    
    setIsLoading(true);
    
    try {
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_encounters_rds',
        {
          patient_name: patientName.trim()
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.encounters && Array.isArray(response.data.encounters)) {
        const encounterData = response.data.encounters;
        console.log('Successfully retrieved encounters:', encounterData);
        
        setEncounters(encounterData);
        setError(null);

        // If we have a currentNoteId, try to find and load the matching encounter
        if (currentNoteId && encounterData.length > 0) {
          const matchingEncounter = encounterData.find(
            encounter => encounter.note_id?.toString() === currentNoteId.toString()
          );
          
          if (matchingEncounter) {
            console.log('Found matching encounter for note ID:', currentNoteId);
            // Mark this as an auto-loaded encounter
            handleReloadEncounter(matchingEncounter, true);
          }
        }
      } else {
        console.warn('No encounters found in response:', response.data);
        setEncounters([]);
        setError('No encounters found');
      }
    } catch (error) {
      console.error('API call failed:', error);
      setError('Failed to load encounters. Please try again later.');
      setEncounters([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (patientName?.trim()) {
      console.log(`Effect triggered with patientName: "${patientName}"`);
      fetchEncounters().then(() => {
        if (!mounted) {
          console.log('Component unmounted, skipping state updates');
        }
      });
    }

    return () => {
      mounted = false;
      console.log('Cleanup: Component unmounting');
    };
  }, [patientName]);

  useImperativeHandle(ref, () => ({
    addEncounter: (encounterData) => {
      setEncounters(prev => Array.isArray(prev) ? [encounterData, ...prev] : [encounterData]);
    },
    refreshEncounters: fetchEncounters
  }));

  const handleReloadEncounter = (encounter, isAutoLoad = false) => {
    if (typeof onReloadEncounter === 'function') {
      // Add isLocked flag based on whether this is an auto-loaded encounter
      const encounterWithLockStatus = {
        ...encounter,
        isLocked: isAutoLoad
      };
      
      console.log('Reloading encounter with data:', encounterWithLockStatus);
      onReloadEncounter(encounterWithLockStatus);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Recent Encounters {isLoading && <span className="text-gray-400">(Loading...)</span>}
      </h2>
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Encounter ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Patient Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Created
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Note ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {encounters.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  No encounters found
                </td>
              </tr>
            ) : (
              encounters.map((encounter) => (
                <tr key={encounter.encounter_id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {encounter.encounter_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {encounter.patient_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(encounter.encounter_timestamp).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {encounter.note_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <button
                      onClick={() => handleReloadEncounter(encounter)}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm"
                    >
                      Reload
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default EncounterHistory;