import React, { useState } from 'react';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@radix-ui/react-collapsible';
import { ChevronDoubleRightIcon, FolderOpenIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

const SubFolder = ({ name }) => {
  return (
    <Collapsible>
      <CollapsibleTrigger asChild>
        <div className="flex items-center space-x-2 cursor-pointer">
          <ChevronDoubleRightIcon className="w-5 h-5" />
          <FolderOpenIcon className="w-5 h-5" />
          <span>{name}</span>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <p className="ml-6 font-bold">Documents For This Subfolder: {name}</p>
      </CollapsibleContent>
    </Collapsible>
  );
};

const DocumentsTab = () => {
  const [fileSection, setFileSection] = useState('');
  const [fileSubsection, setFileSubsection] = useState('');
  const [file, setFile] = useState(null);

  const handleFileUpload = async () => {
    try {
      await axios.post('/api/upload', {
        fileSection,
        fileSubsection,
        file
      });
      // Handle success, e.g. display notification
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="p-4 space-y-4">
      {/* File uploader section */}
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_section">File Section</label>
        <select
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          id="file_section"
          value={fileSection}
          onChange={(e) => setFileSection(e.target.value)}
        >
          <option value="">Select a file section</option>
          <option value="office">Office Documents</option>
          <option value="clinical">Clinical Documents</option>
          <option value="billing">Billing Documents</option>
        </select>
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_subsection">File Subsection</label>
        <select
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          id="file_subsection"
          value={fileSubsection}
          onChange={(e) => setFileSubsection(e.target.value)}
        >
          <option value="">Select a file subsection</option>
          {fileSection === 'office' && (
            <>
              <option value="registration-forms">Registration Forms</option>
              <option value="phone-intake">Phone Intake</option>
              <option value="oop-form">OOP Form</option>
              <option value="schedule-transcript">Schedule Transcript</option>
            </>
          )}
          {fileSection === 'clinical' && (
            <>
              <option value="intake">Intake</option>
              <option value="intake-questionnaire">Intake Questionnaire</option>
              <option value="prior-reports">Prior Reports From Other Providers</option>
              <option value="initial-tp">Initial TP</option>
              <option value="internal-referral">Internal Referral</option>
              <option value="iep">IEP</option>
              <option value="sensory-profile">Sensory Profile</option>
            </>
          )}
          {fileSection === 'billing' && (
            <>
              <option value="insurance-card">Insurance Card</option>
              <option value="insurance-verification">Verification of Insurance</option>
              <option value="prescription">Prescription</option>
              <option value="authorization-request">Authorization Request</option>
              <option value="authorization-denial">Authorization Denial</option>
              <option value="authorization-approval">Authorization Approval</option>
            </>
          )}
        </select>
      </div>
      <div className="flex items-center space-x-12">
        <div className="w-3/4">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload file</label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="file_input"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <button
          className="w-1/4 bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded mt-6"
          onClick={handleFileUpload}
        >
          Upload File
        </button>
      </div>

      {/* Collapsible FolderOpenIcon structure */}
      <div className="space-y-4">
        <Collapsible>
          <CollapsibleTrigger asChild>
            <div className="flex items-center space-x-2 text-lg font-semibold cursor-pointer">
              <ChevronDoubleRightIcon className="w-5 h-5" />
              <FolderOpenIcon className="w-5 h-5" />
              <span>Office Documents</span>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="ml-6 space-y-2">
              <SubFolder name="Registration Forms" />
              <SubFolder name="Phone Intake" />
              <SubFolder name="OOP Forms" />
              <SubFolder name="Schedule Transcript" />  
            </div>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTrigger asChild>
            <div className="flex items-center space-x-2 text-lg font-semibold cursor-pointer">
              <ChevronDoubleRightIcon className="w-5 h-5" />
              <FolderOpenIcon className="w-5 h-5" />
              <span>Clinical Documents</span>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="ml-6 space-y-2">
              <SubFolder name="Intake" />
              <SubFolder name="Intake Questionnaire" />
              <SubFolder name="Prior Reports From Other Providers (EI, CPSE, ENT, etc.)" />
              <SubFolder name="Initial TP" />
              <SubFolder name="Internal Referral" />
              <SubFolder name="Sensory Profile" />
            </div>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTrigger asChild>
            <div className="flex items-center space-x-2 text-lg font-semibold cursor-pointer">
              <ChevronDoubleRightIcon className="w-5 h-5" />
              <FolderOpenIcon className="w-5 h-5" />
              <span>Billing Documents</span>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="ml-6 space-y-2">
              <SubFolder name="Insurance Card" />
              <SubFolder name="Verification Of Insurance" />
              <SubFolder name="Prescription" />
              <SubFolder name="Authorization Request" />
              <SubFolder name="Authorization Denial" />
              <SubFolder name="Authorization Approval" />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};

export default DocumentsTab;