import React, { useState } from 'react';
import { Camera } from "lucide-react";

const ProfilePictureUpload = ({ imageURL, onFileSelect, name }) => {
  const [isHovered, setIsHovered] = useState(false);
  const fileInputRef = React.useRef(null);

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          const maxDimension = 800; // Max width/height in pixels
          
          if (width > height && width > maxDimension) {
            height = Math.round((height * maxDimension) / width);
            width = maxDimension;
          } else if (height > maxDimension) {
            width = Math.round((width * maxDimension) / height);
            height = maxDimension;
          }
          
          canvas.width = width;
          canvas.height = height;
          
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', 0.7); // Adjust quality (0.7 = 70% quality)
        };
      };
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    try {
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert('Please select an image under 5MB');
        return;
      }
      const compressedFile = await compressImage(file);
      console.log('Original size:', file.size, 'Compressed size:', compressedFile.size);
      const newEvent = {
        ...event,
        target: {
          ...event.target,
          files: [compressedFile]
        }
      };

      onFileSelect(newEvent);
    } catch (error) {
      console.error('Error processing image:', error);
      alert('Error processing image. Please try again.');
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div 
      className="w-32 h-32"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/png, image/jpeg"
        className="hidden"
      />
      <div className="relative w-32 h-32 z-10">
        <div className="w-32 h-32 rounded-full overflow-hidden">
          <img
            alt={name}
            src={imageURL || "https://via.placeholder.com/150"}
            className="w-full h-full object-cover"
          />
        </div>
        <div 
          className={`absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 transition-opacity duration-200 cursor-pointer
            ${isHovered ? 'opacity-100' : 'opacity-0'}`}
        >
          <Camera className="w-8 h-8 text-white" />
        </div>
      </div>
    </div>
  );
};

export default ProfilePictureUpload;