import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import CalendarPage from './components/CalendarPage'; 
import PatientProfile from './components/PatientProfile';
import ProfileTable from './components/ProfileTable';
import Chat from './components/Chat';
import HR from './components/HR';
import EmployeeProfile from './components/EmployeeProfile';
import EmployeeProfileTabs from './components/EmployeeProfileTabs';
import Alerts from './components/Alerts';
import { Authenticator } from '@aws-amplify/ui-react';
import AllPatients from './components/AllPatients';
import DemographicsTab from './components/ProfileTableComps/DemographicsTab';
import BillingTab from './components/ProfileTableComps/BillingTab';
import NotesTab from './components/ProfileTableComps/NotesTab';
import ScheduleTab from './components/ProfileTableComps/ScheduleTab';
import TwoProvView from './components/TwoProvView'
import WeekView from './components/WeekView';
import CreateNote from './components/CreateNote';
import Forms from './components/Forms';
import ViewNote from './components/ViewNote';
import Charge from './components/Charge';
import { UserProvider } from './components/UserContext';
import CaptureCharge from './components/CaptureCharge';
import ModifyEvent from './components/ModifyEvent';
import ChargingTable from './components/ChargingTable';
import Reports from './components/Reports';
import ProfilePictureUpload from './components/ProfilePictureUpload';
import EncounterHistory from './components/EncounterHistory';

const App = () => {
  return (
    <UserProvider>
    <Authenticator>
      {({ signOut }) => (
        <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/demographicstab" element={<DemographicsTab />} />
          <Route path="/billingtab" element={<BillingTab />} />
          <Route path="/notestab" element={<NotesTab />} />
          <Route path="/scheduletab" element={<ScheduleTab />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/patients" element={<AllPatients />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/patients/:id" element={<PatientProfile />} />
          <Route path="/employee/:id" element={<EmployeeProfile />} />
          <Route path="/createnote" element={<CreateNote />} />
          <Route path="/viewnote/:id" element={<ViewNote />} />
          <Route path="/hr" element={<HR />} />
          {/* <Route path="/hamburger" element={<Hamburger />} /> */}
          <Route path="/employeeprofiletabs" element={<EmployeeProfileTabs />} />
          <Route path="/alerts" element={<Alerts />} />
          <Route path="/profiletable" element={<ProfileTable />} />
          <Route path="/twoprovview" element={<TwoProvView />} />
          <Route path="/weekview" element={<WeekView />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/charge" element={<Charge />} />
          <Route path="/capturecharge/:id" element={<CaptureCharge />} />
          <Route path="/modifyevent/:id" element={<ModifyEvent />} />
          <Route path="/chargingtable" element={<ChargingTable />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/profilepictureupload" element={<ProfilePictureUpload />} />
          <Route path="/encounterhistory" element={<EncounterHistory />} />
          {/* Add any other routes you need */}
        </Routes>
      </Router>
      )}
    </Authenticator>
    </UserProvider>
  );
};

export default App;