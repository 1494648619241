import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="h-1/2 bg-white p-6 rounded-lg w-full max-w-2xl relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
        {children}
      </div>
    </div>
  );
};

const Charge = () => {
  const navigate = useNavigate();
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showSaveCardPrompt, setShowSaveCardPrompt] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerData, setCustomerData] = useState({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    addressLine: '',
    country: '',
    city: '',
    postalCode: '',
    cardHolderName: '',
  });

  const handlePaymentSubmit = async (token) => {
    setPaymentStatus(null);
    setError(null);
    setPaymentDetails(null);
  
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/square-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: JSON.stringify({
            sourceId: token.token,
            amount: Math.round(parseFloat(paymentAmount) * 100),
            currency: 'USD',
          })
        }),
      });

      const result = await response.json();
      console.log("Response result:", result);
  
      if (result.statusCode === 200) {
        // Parse the body string to JSON
        const paymentResponse = JSON.parse(result.body);
        setPaymentStatus('Payment successful');
        setPaymentDetails(paymentResponse.payment);
        setShowSaveCardPrompt(true);
      } else {
        // Parse error response
        const errorResponse = JSON.parse(result.body);
        const errorDetails = errorResponse.error[0];
        setError(`${errorDetails.category}: ${errorDetails.code}`);
        setPaymentStatus('Payment Failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('An unexpected error occurred while processing the payment');
      setPaymentStatus('Payment Failed');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateCustomer = async () => {
    try {
      const response = await fetch('YOUR_API_GATEWAY_ENDPOINT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...customerData,
          paymentId: paymentDetails.id
        }),
      });

      if (response.ok) {
        setShowCustomerModal(false);
        navigate('/chargingtable');
      } else {
        throw new Error('Failed to create customer');
      }
    } catch (error) {
      console.error('Error creating customer:', error);
      setError('Failed to create customer and save card');
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 mt-20">
        <h1 className="text-2xl font-bold mb-4">Process Payment</h1>
        <div className="mb-4">
          <label htmlFor="amount" className="block mb-2">Payment Amount ($)</label>
          <input
            type="number"
            id="amount"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter amount"
          />
        </div>
        <PaymentForm
          applicationId="sq0idp-MZ2YcyqrxXaSfw-0tgQ6mA"
          locationId="T9FBNCVS8WQWY" // Replace with your actual location ID
          environment="production"
          cardTokenizeResponseReceived={async (token, buyer) => {
            await handlePaymentSubmit(token);
          }}
        >
          <CreditCard />
        </PaymentForm>
        
        {paymentStatus === 'Payment successful' && (
          <div className="mt-4 space-y-2">
            <div className="p-2 bg-green-100 text-green-700 rounded">
              {paymentStatus}
            </div>
            {paymentDetails && (
              <div className="p-2 bg-blue-50 text-blue-700 rounded">
                <p>Payment ID: {paymentDetails.id}</p>
                <p>Amount: ${(paymentDetails.amount_money.amount / 100).toFixed(2)}</p>
                <p>Status: {paymentDetails.status}</p>
                <p>Card: {paymentDetails.card_details.card.card_brand} ending in {paymentDetails.card_details.card.last_4}</p>
              </div>
            )}
          </div>
        )}
        
        {paymentStatus === 'Payment Failed' && (
          <div className="mt-4 space-y-2">
            <div className="p-2 bg-red-100 text-red-700 rounded">
              {paymentStatus}
            </div>
            {error && (
              <div className="p-2 bg-red-50 text-red-700 rounded">
                Error: {error}
              </div>
            )}
          </div>
        )}

        {showSaveCardPrompt && (
          <div className="mt-4 p-4 bg-gray-50 rounded">
            <p className="mb-4">Would you like to save this card for future use?</p>
            <div className="space-x-4">
              <button
                onClick={() => navigate('/chargingtable')}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                No
              </button>
              <button
                onClick={() => {
                  setShowSaveCardPrompt(false);
                  setShowCustomerModal(true);
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        )}

        <Modal
          isOpen={showCustomerModal}
          onClose={() => setShowCustomerModal(false)}
        >
          <div className="space-y-6">
            <h2 className="text-xl font-bold">Customer Details</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block mb-1">Given Name</label>
                <input
                  type="text"
                  name="givenName"
                  value={customerData.givenName}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Family Name</label>
                <input
                  type="text"
                  name="familyName"
                  value={customerData.familyName}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={customerData.phoneNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Email</label>
                <input
                  type="email" name="email"
                  value={customerData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>

            <h2 className="text-xl font-bold mt-6">Card Details</h2>
            <div className="space-y-4">
              <h3 className="font-semibold">Billing Address</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                  <label className="block mb-1">Address Line</label>
                  <input type="text" name="addressLine" value={customerData.addressLine} onChange={handleInputChange} className="w-full p-2 border rounded"/>
                </div>
                <div>
                  <label className="block mb-1">Country</label>
                  <input type="text" name="country" value={customerData.country} onChange={handleInputChange} className="w-full p-2 border rounded"/>
                </div>
                <div>
                  <label className="block mb-1">City</label>
                  <input type="text" name="city" value={customerData.city} onChange={handleInputChange} className="w-full p-2 border rounded"/>
                </div>
                <div className="col-span-2">
                  <label className="block mb-1">Postal Code</label>
                  <input type="text" name="postalCode" value={customerData.postalCode} onChange={handleInputChange} className="w-full p-2 border rounded"/>
                </div>
                <div className="col-span-2">
                  <label className="block mb-1">Card Holder Full Name</label>
                  <input type="text" name="cardHolderName" value={customerData.cardHolderName} onChange={handleInputChange} className="w-full p-2 border rounded"/>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleCreateCustomer}
                className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Create Customer and Attach Card
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Charge;