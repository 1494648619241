import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FingerPrintIcon, PencilIcon, XMarkIcon, DocumentCheckIcon, PaperAirplaneIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import Header from './Header';
import axios from 'axios';

const ViewNote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { note } = location.state || {};
  const { patient } = location.state || {};
  
  const [noteType, setNoteType] = useState(note?.note_subtype || '');
  const [noteContent, setNoteContent] = useState(note?.note_content || '');
  const [isEditing, setIsEditing] = useState(false);
  const [dateService, setDateService] = useState(note?.date_service || new Date().toISOString().split('T')[0]);
  const [getProviders, setGetProviders] = useState([]);
  const [provider, setProvider] = useState('');
  const [selectedProvID, setSelectedProvId] = useState(note?.created_by || null);
  const [noteID, setNoteID] = useState(note?.id || null);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [billingProvider, setBillingProvider] = useState('');
  const noteSection = note?.note_type || 'Daily Session';

  useEffect(() => {
    handleSubmit();
    console.log("Note ID: ", noteID);
    console.log("Note Signed: ", note.signed_by);
    console.log("Note Charged:", note.charged);
  }, []);

  useEffect(() => {
    if (note && getProviders.length > 0) {
      const providerName = getProviders.find(p => p.id === note.created_by)?.name || '';
      setProvider(providerName);
      setSelectedProvId(note.created_by);
    }
  }, [note, getProviders]);

  const onCaptureCharge = () => {
    navigate(`/capturecharge/${noteID}`, {
      state: {
        patientID: patient?.PatientID,
        note: note,
        providerName: provider
      }
    });
  };

  const handleSignNote = () => {
     setShowSignModal(true);
  };

  const handleConfirmSign = () => {
    if (!billingProvider) {
      alert('Please select a billing provider');
      return;
    }
    setShowSignModal(false);
    setShowConfirmModal(true);
  };

  const handleFinalSign = async () => {
    console.log("Handling final sign");
    try {
      console.log("Attempting to sign note with data:", {
        note_id: note.id,
        signed_by: billingProvider
      });
      
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/sign_note',
        {
          note_id: note.id,
          signed_by: billingProvider
        }
      );
      
      console.log("API Response:", response);
      
      if (response.status === 200) {
        setShowConfirmModal(false);
        setBillingProvider('');
        const updatedNote = {
          ...note,
          signed_by: billingProvider,
          signed_at: new Date().toISOString()
        };
        navigate(`/viewnote/${note.id}`, {state: { note: updatedNote }});
      }
    } catch (error) {
      console.error('Error signing note:', error.response || error);
      alert('Error signing note: ' + (error.response?.data?.error || error.message));
    }
};

  const SignNoteModal = () => {
    const billingProviders = [
      "Michele DeSimone, SLP",
      "Jaimie Delfino, SLP",
      "Emily Rodriguez, SLP",
      "Nicole Nardone, SLP",
      "Diona Rosano, SLP",
      "Nicole Ianni, SLP",
      "Alexandra Mandara, OTR/L",
      "Delia Pellettiere, OTR/L",
      "Stephanie Vanasco, OTR/L",
      "Rebecca Adamo, OTR/L",
    ];
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg w-96">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Select Billing Provider</h3>
            <button onClick={() => setShowSignModal(false)} className="text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="mb-4">
            <select
              value={billingProvider}
              onChange={(e) => setBillingProvider(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="">Select provider</option>
              {billingProviders.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={handleConfirmSign}
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
          >
            Sign
          </button>
        </div>
      </div>
    );
  };

  const ConfirmSignModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Confirm Signature</h3>
          <button onClick={() => setShowConfirmModal(false)} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <p className="mb-4">Are you sure you want to sign this note?</p>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowConfirmModal(false)}
            className="flex-1 py-2 border rounded-lg hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleFinalSign}
            className="flex-1 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
          >
            Yes, Sign
          </button>
        </div>
      </div>
    </div>
  );

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"');
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => ({
            id: provider.pr_id,
            name: provider.pr_name
          }));
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const getNoteTypeOptions = () => {
    switch (noteSection) {
      case 'Daily Session':
        return ['Daily Session', 'ST Daily Session Note', 'OT Daily Session Note', 'Speech Therapy Intake', 'Occupational Therapy Intake'];
      case 'Peer Group':
        return ['Peer Group', 'Peer Group Notes'];
      case 'Treatment Plan':
        return ['Treatment Plan', 'ST Initial Treatment Plan', 'OT Initial Treatment Plan', 'ST Treatment Plan Update', 'OT Treatment Plan Update'];
      default:
        return ['Personal', 'Information From Caregiver', 'Assessment Notes', 'Misc.'];
    }
  };
  
  const noder = getNoteTypeOptions()[0];
  const noteTypeOptions = getNoteTypeOptions().slice(1);

  const onPencil = () => {
    setIsEditing(!isEditing);
  };

  const onMarkError = () => {
    if (!noteType || !noteContent) {
      alert('Please fill in all required fields.');
      return;
  }
  updateNote('Error');
  };

  const onSaveClose = () => {
    if (!noteType || !noteContent) {
        alert('Please fill in all required fields.');
        return;
    }
    updateNote('In-Progress');
  };

  const onSaveSubmit = () => {
    if (!noteType || !noteContent) {
        alert('Please fill in all required fields.');
        return;
    }
    updateNote('Complete');
  };

  const updateNote = async (status) => {
    try {
        const response = await axios.put(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/update_note/${note.id}`, {
            note_id: note.id,
            note_type: noder,
            note_subtype: noteType,
            note_content: noteContent,
            note_status: status,
            date_of_service: dateService,
            event_id: note.event_id || null,
            event_sch_id: note.event_sch_id || null,
            created_by: selectedProvID,
            updated_at: new Date().toISOString().split('T')[0],
            patient_id: patient.PatientID
        });

        console.log('Note updated:', response.data);
        
        if (response.data) {
            navigate(`/patients/${patient.PatientID}`, {
                state: { patient }
            });
        } else {
            alert('Note not found or update failed');
        }
    } catch (error) {
        console.error('Error updating note:', error);
        alert('Error updating note: ' + (error.response?.data?.error || 'Unknown error'));
    }
};

  return (
    <div>
      <Header />
      <div className="container mx-auto pt-24">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">View/Edit {noder} Note</h1>
          
          <button onClick={onPencil} className="p-2 rounded-full hover:bg-gray-200">
            <PencilIcon className="h-6 w-6 text-gray-600" />
          </button>
        </div>
        <div className="flex justify-between">
          <div className="relative w-5/12 mb-4">
            <input
              type="date"
              value={dateService}
              onChange={(e) => setDateService(e.target.value)}
              disabled={!isEditing}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
            <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
              Date of Service
            </label>
          </div>
          <div className="relative w-5/12 mb-4">
            <select
              value={noteType}
              onChange={(e) => setNoteType(e.target.value)}
              disabled={!isEditing}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            >
              <option value="" disabled hidden>Select note type</option>
              {noteTypeOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
            <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
              Note Type
            </label>
          </div>
        </div>
        <div className="relative w-full mb-4">
          <select 
            value={provider} 
            onChange={(e) => {
              const selectedProvider = getProviders.find(p => p.name === e.target.value);
              setProvider(e.target.value);
              setSelectedProvId(selectedProvider ? selectedProvider.id : null);
            }} 
            disabled={!isEditing}
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
            id="grid-state"
          >
            <option value="">Select provider</option>
            {getProviders.map((provider) => (
              <option key={provider.id} value={provider.name}>
                {provider.name}
              </option>
            ))}
          </select>
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
            Provider
          </label>
        </div>

        <div className="relative w-full mb-4">
          <textarea
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            disabled={!isEditing}
            className="block px-2.5 pb-2.5 pt-4 w-full h-[25rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=""
          ></textarea>
          <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
            Note Content
          </label>
        </div>

        <div className="flex justify-between items-center my-4">
          <div className="flex space-x-2">
            {note?.charged === true ? (
              <button onClick={onCaptureCharge} className="flex items-center px-4 py-2 bg-emerald-400 text-black rounded-md hover:underline duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                View Encounter
              </button>
            ) : (
              <button onClick={onCaptureCharge} className="flex items-center px-4 py-2 bg-white text-black rounded-md hover:underline duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                Capture Charge
              </button>
            )}
            {!note?.signed_by ? (
              <button onClick={handleSignNote} className="flex items-center px-4 py-2 bg-white text-black rounded-md hover:underline duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                <FingerPrintIcon className="h-5 w-5 mr-2" />
                Sign This Note
              </button>
            ) : (
              <div className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md">
                Signed by {note.signed_by} at {new Date(note.signed_at).toLocaleString()}
              </div>
            )}
          </div>
          <div className="flex space-x-2">
            <button onClick={onMarkError} className="flex items-center px-4 py-2 bg-white text-red-500 rounded-md hover:bg-red-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
              <XMarkIcon className="h-5 w-5 mr-2" />
              Mark Error
            </button>
            <button onClick={onSaveClose} className="flex items-center px-4 py-2 bg-white text-blue-500 rounded-md hover:bg-blue-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <DocumentCheckIcon className="h-5 w-5 mr-2" />
              Save and Close
            </button>
            <button onClick={onSaveSubmit} className="flex items-center px-4 py-2 bg-white text-green-500 rounded-md hover:bg-green-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
              <PaperAirplaneIcon className="h-5 w-5 mr-2" />
              Submit
            </button>
          </div>
        </div>
      </div>
      {showSignModal && <SignNoteModal />}
      {showConfirmModal && <ConfirmSignModal />}
    </div>
  );
};

export default ViewNote;